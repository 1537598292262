$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > '20') {
        $('.navbar-default').addClass('nav-white');
    } else if (scroll < '20') {
        $('.navbar-default').removeClass('nav-white');
    }
});

$(document).ready(function () {
    var scroll = $(window).scrollTop();
    if (scroll > '20') {
        $('.navbar-default').addClass('nav-white');
    } else if (scroll < '20') {
        $('.navbar-default').removeClass('nav-white');
    }
});
$('.animated').each(function () {
    $(this).appear(function () {

        var element = $(this);

        var animation = element.attr('data-animation');
        if (!element.hasClass('visible')) {
            var animationDelay = element.attr('data-animation-delay');
            setTimeout(function () {
                    element.addClass(animation + ' visible');
                }, animationDelay
            );
        }
    });

});
$('body').scrollspy({
    target: '#nav-main',
    offset: 65
});

$(function () {
    $('a.page-scroll').bind('click', function (event) {
        if ($(window).width < 768) {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - 50
            }, 1500, 'easeInOutExpo');
            event.preventDefault();
        } else {
            var $anchor = $(this);
            $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top - 65
            }, 1500, 'easeInOutExpo');
            event.preventDefault();
        }

    });
});

$('.why-slider').slick({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    prevArrow: $(".why-slider-left"),
    nextArrow: $(".why-slider-right"),
    autoplay: false,
    autoplaySpeed: 3000,
});

$('.client-slider').slick({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: $(".previous-butn"),
    nextArrow: $(".next-butn"),
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 599,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});